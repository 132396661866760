<template>



	<div class="container">
		
		<div class="box">
			<br>
			<div class="con">
				<p>当前账户余额 <span class='price'>¥ {{ price }}</span></p>
				<el-form ref="form" label-width="120px">

					<el-form-item label="请选择充值金额">
						<el-radio-group v-model="id" size="small" @change="change">
							<el-radio :label="item.id" border v-for="(item,index) in list" :key="index">
								充值{{ item.money }}元送{{ item.give_money }}元</el-radio>

						</el-radio-group>
					</el-form-item>



				</el-form>
				<div class="btnbox">
					<el-button class='btn' @click="recharge">立即充值</el-button>
				</div>

			</div>

			<el-dialog title="请微信扫码支付" :visible.sync="dialogVisible" width="250px" :before-close="close">
				<vue-qr :text="code" :size="200" style="margin:0 auto"></vue-qr>

			</el-dialog>




		</div>
	</div>
	</div>


</template>

<script>
	import vueQr from 'vue-qr'
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				price: '',
				id: '',
				list: '',
				code: '',
				dialogVisible: false,
				form: {

				},

			}
		},

		created() {
			this.balance();
			this.PayList();
		},
		mounted() {

		},

		methods: {
			
			
			async balance() {
				let res = await this.$api.balance();
				this.price = res.balance;
			},
			async PayList() {
				let res = await this.$api.PayList();
				this.list = res.data;
			},
			
			close(){
				this.dialogVisible = false;
				clearInterval(this.timer)
			},



			async recharge() {
				if(typeof this.id == "undefined" || this.id == null || this.id == ""){
					this.$message.error('请选择充值栏目');
					return false;
				}
				
				console.log('id', this.id)
				
				
				let res = await this.$api.scanBalancePay({
					'id': this.id
				});
				this.code = res.data.code_url;
				this.dialogVisible = true;
				console.log('code', this.code);
                this.timer = setInterval(() => {
                	this.scanPaySuccessful(res.data.order_number);
                }, 1000)

			},
			
			
			async scanPaySuccessful(order) {
				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.dialogVisible = false;
					clearTimeout(this.timer)
					this.$message.success('支付成功');
					this.balance();
				}
			},
			
			

			change(e) {
				console.log('e', e)
				this.id = e;
			},


			go(url) {
				this.$router.push({
					path: url,
				})
			},
			back() {

				this.$router.back();
			}
		}


	}
</script>

<style scoped lang="scss">
	.page {

		height: 80vh;
		margin-top: 30px;

	}

	.box {
		margin: 20px auto 0;
		background-color: #fff;
		width: 100%;
		height: 50vh;
	}

	.wamp {
		width: 1300px;
		margin: 0 auto;
	}

	.top {
		margin-top: -15px;
		height: 30px;
		color: #fff;
		background-color: #259DDC;

	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.con {
		width: 800px;
		height: 300px;
		border: 1px solid #ccc;
		margin: 100px auto 0;


		p {
			line-height: 50px;
			padding-left: 15px;
			font-size: 18px;

			.price {
				font-weight: bold;
				color: red;
			}
		}

		.btnbox {
			text-align: center;
		}

		.el-radio {
			margin: 5px;
		}

		.btn {
			margin: 0px auto;
		}



	}
</style>
